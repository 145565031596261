var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visible,
            closable: true,
            size: "normal",
            title: "调整转正日期",
            "ok-text": "保存",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "调整方式" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { name: "radioGroup" },
                      model: {
                        value: _vm.workerType,
                        callback: function ($$v) {
                          _vm.workerType = $$v
                        },
                        expression: "workerType",
                      },
                    },
                    [
                      _c(
                        "a-radio",
                        { staticClass: "date", attrs: { value: "1" } },
                        [_vm._v("调整日期")]
                      ),
                      _c(
                        "a-radio",
                        { staticClass: "date", attrs: { value: "2" } },
                        [_vm._v("调整试用期")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.workerType == "1"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "计划转正日期" } },
                    [_vm._v(" " + _vm._s(_vm.form.plantTime) + " ")]
                  )
                : _vm._e(),
              _vm.workerType == "1"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "转正日期", prop: "time" } },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          "value-format": "YYYY-MM-DD",
                          placeholder: "请选择时间",
                          "disabled-date": _vm.disabledDate,
                        },
                        model: {
                          value: _vm.form.planFormalDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "planFormalDate", $$v)
                          },
                          expression: "form.planFormalDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.workerType == "2"
                ? _c("a-form-model-item", { attrs: { label: "原试用期限" } }, [
                    _vm._v(" " + _vm._s(_vm.form.trialData) + " "),
                  ])
                : _vm._e(),
              _vm.workerType == "2"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "调整试用期限" } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { name: "select", "no-style": "" } },
                        [
                          _c("dictionariesInput", {
                            staticClass: "dict-input",
                            attrs: { parameter: "probation" },
                            model: {
                              value: _vm.form.selectTrialData,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "selectTrialData", $$v)
                              },
                              expression: "form.selectTrialData",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }